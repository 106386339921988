import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77')
];

export const server_loads = [];

export const dictionary = {
		"/": [7],
		"/app": [8,[2]],
		"/app/bank_accounts/create": [11,[2]],
		"/app/bank_accounts/[...slug]/bank_transactions/create": [10,[2]],
		"/app/bank_accounts/[...slug]": [9,[2]],
		"/app/bank_keys": [12,[2]],
		"/app/banking/bank_devices": [13,[2]],
		"/app/banking/bank_payrolls/create": [15,[2]],
		"/app/banking/bank_payrolls/[...slug]": [14,[2]],
		"/app/banking/bank_recipients/create": [17,[2]],
		"/app/banking/bank_recipients/[...slug]": [16,[2]],
		"/app/cartolas": [18,[2]],
		"/app/companies/new": [19,[2]],
		"/app/complete-information": [20,[2]],
		"/app/dashboard": [21,[2]],
		"/app/erp/payouts/company_refunds": [22,[2]],
		"/app/erp/payouts/credit_notes": [23,[2]],
		"/app/erp/payouts/expense_reports/[...slug]": [24,[2]],
		"/app/erp/payouts/imprest_funds": [25,[2]],
		"/app/erp/payouts/imprest_funds/new": [27,[2]],
		"/app/erp/payouts/imprest_funds/[...slug]": [26,[2]],
		"/app/erp/payouts/payables": [28,[2]],
		"/app/erp/payouts/payables/approved": [30,[2]],
		"/app/erp/payouts/payables/declined": [31,[2]],
		"/app/erp/payouts/payables/mine": [32,[2]],
		"/app/erp/payouts/payables/new": [33,[2]],
		"/app/erp/payouts/payables/paid": [34,[2]],
		"/app/erp/payouts/payables/pay": [35,[2]],
		"/app/erp/payouts/payables/pending": [36,[2]],
		"/app/erp/payouts/payables/scheduled": [37,[2]],
		"/app/erp/payouts/payables/[...slug]": [29,[2]],
		"/app/invoices/sii_account": [38,[2]],
		"/app/payment": [39,[2]],
		"/app/settings/api_keys": [40,[2,3]],
		"/app/settings/bulk_load": [41,[2,3]],
		"/app/settings/company": [42,[2,3]],
		"/app/settings/contracts": [43,[2,3]],
		"/app/settings/integrations": [44,[2,3]],
		"/app/settings/integrations/add": [45,[2,3]],
		"/app/settings/kyc": [46,[2,3]],
		"/app/settings/payments": [47,[2,3]],
		"/app/settings/profile": [48,[2,3]],
		"/app/settings/sii": [49,[2,3]],
		"/app/settings/team": [50,[2,3]],
		"/app/transactions": [51,[2]],
		"/app/vendor_cards/activate_success": [54,[2]],
		"/app/vendor_cards/activate": [53,[2]],
		"/app/vendor_cards/create": [55,[2]],
		"/app/vendor_cards/[...slug]": [52,[2]],
		"/app/vendors": [56,[2]],
		"/app/vendors/[...slug]/details": [57,[2,4]],
		"/app/vendors/[...slug]/general": [58,[2,4]],
		"/app/vendors/[...slug]/rules": [59,[2,4]],
		"/confirm-refund": [60],
		"/impersonate": [61],
		"/invitation": [62,[5]],
		"/invitation/invalid": [63,[5]],
		"/invitation/user": [64,[5]],
		"/jobs": [65],
		"/landing/transfers": [66],
		"/login": [67],
		"/logout": [68],
		"/mfa-notify": [69],
		"/onboarding": [70,[6]],
		"/pay": [71],
		"/recover-password": [72],
		"/refunds": [73],
		"/terms": [74],
		"/transfers": [75],
		"/tyc": [76],
		"/verify": [77]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';